import styled, { css } from 'styled-components';

import { TextColor, TextTransform } from '@Helpers/types/text';

export interface HeadlineProps {
  $transform: TextTransform;
  $color: TextColor;
}

export const defaultHeadlineStyles = (transform: TextTransform, color: TextColor) => css`
  word-wrap: break-word;
  margin-bottom: ${(props) => props.theme.space.default_16};
  font-family: ${(props) => props.theme.font.headlineFamily};
  transition: color ${(props) => props.theme.transition.theme};

  text-transform: ${transform};
  color: ${(props) => props.theme.palette.text[color]};
`;

export const h1Styles = (transform: TextTransform, color: TextColor) => css`
  ${defaultHeadlineStyles(transform, color)}
  font-size: ${(props) => props.theme.font.headline.one.fontSize};
  font-weight: ${(props) => props.theme.font.headline.one.fontWeight};
  line-height: ${(props) => props.theme.font.headline.one.lineHeight};
  letter-spacing: ${(props) => props.theme.font.headline.one.letterSpacing};
`;

export const h2Styles = (transform: TextTransform, color: TextColor) => css`
  ${defaultHeadlineStyles(transform, color)}
  font-size: ${(props) => props.theme.font.headline.two.fontSize};
  font-weight: ${(props) => props.theme.font.headline.two.fontWeight};
  line-height: ${(props) => props.theme.font.headline.two.lineHeight};
  letter-spacing: ${(props) => props.theme.font.headline.two.letterSpacing};
`;

export const h3Styles = (transform: TextTransform, color: TextColor) => css`
  ${defaultHeadlineStyles(transform, color)}
  font-size: ${(props) => props.theme.font.headline.three.fontSize};
  font-weight: ${(props) => props.theme.font.headline.three.fontWeight};
  line-height: ${(props) => props.theme.font.headline.three.lineHeight};
  letter-spacing: ${(props) => props.theme.font.headline.three.letterSpacing};
`;

export const h4Styles = (transform: TextTransform, color: TextColor) => css`
  ${defaultHeadlineStyles(transform, color)}
  font-size: ${(props) => props.theme.font.headline.four.fontSize};
  font-weight: ${(props) => props.theme.font.headline.four.fontWeight};
  line-height: ${(props) => props.theme.font.headline.four.lineHeight};
  letter-spacing: ${(props) => props.theme.font.headline.four.letterSpacing};
`;

export const h5Styles = (transform: TextTransform, color: TextColor) => css`
  ${defaultHeadlineStyles(transform, color)}
  font-size: ${(props) => props.theme.font.headline.five.fontSize};
  font-weight: ${(props) => props.theme.font.headline.five.fontWeight};
  line-height: ${(props) => props.theme.font.headline.five.lineHeight};
  letter-spacing: ${(props) => props.theme.font.headline.five.letterSpacing};
`;

export const H1 = styled.h1<HeadlineProps>`
  ${(props) => h1Styles(props.$transform, props.$color)}
`;
export const H2 = styled.h2<HeadlineProps>`
  ${(props) => h2Styles(props.$transform, props.$color)}
`;
export const H3 = styled.h3<HeadlineProps>`
  ${(props) => h3Styles(props.$transform, props.$color)}
`;
export const H4 = styled.h4<HeadlineProps>`
  ${(props) => h4Styles(props.$transform, props.$color)}
`;
export const H5 = styled.h5<HeadlineProps>`
  ${(props) => h5Styles(props.$transform, props.$color)}
`;
